import * as React from 'react'
import { Link } from 'gatsby'
let Scroll = require('react-scroll');
let scroller = Scroll.scroller;
var Element = Scroll.Element;

import Page from '../components/Page'
import Container from '../components/Container'
import IndexLayout from '../layouts'
import styled from "@emotion/styled";
const P = styled.p`
font-weight: normal;
font-size: 1.1em;
letter-spacing: 0.1em;
color: #333;
padding-bottom: 2.5em;
line-height: 1.5em;`



const Div = styled.div`
width:100%;

`

const IFrame = styled.iframe`
border:0;
margin-top:1%;

`
const Div2=styled.div`
  @media screen and (max-width: 870px) {
text-align:center; }
`

class Lage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true
    }
  }
  componentDidMount() {
    scroller.scrollTo('header', {
      duration: 1500,
      smooth: true,
    })
  }


  render() {
    return (
  <IndexLayout>
    <Page>
      <Container>
        <Element id={'header'}/>
        <br/>
        <Div2>
        <h1>Mit uns sind Sie mittendrin</h1>
        </Div2>
        <br/>
        <br/>

        <P>Sie finden uns in erstklassiger Lage, nur wenige Meter von der Friedrichstraße und dem Potsdamer Platz entfernt.
          An der Schnittstelle zwischen kosmopolitischem Kreuzberg und urbaner Mitte, im Herzen Berlins.
          Eingebettet in einem frisch restaurierten Altbau laden dort individuell gestaltete Private Offices zum Coworking ein. Der ruhige,
          begrünte Innenhof bietet ebenfalls ausreichend Möglichkeiten, in der nachmittäglichen Sonne neue Kraft zu tanken.</P>

        <h2>Verkehrsanbindungen</h2>

        <ul>
          <li>Parkplätze in der Umgebung verfügbar</li>
          <li>Fahrradständer verfügbar</li>
          <li>5 min. zum U-Bahnhof Hallesches Tor, mit den U-Bahn-Linien U1, U3, U6</li>
          <li>8 min. zum U-Bahnhof Möckernbrücke, mit den U-Bahn-Linien U1, U3, U7</li>
          <li>8 min. zum S-Bahnhof Anhalter Bahnhof S1, S2, S25</li>
          <li>3 min. zum Bus M41, M248</li>
          <li>15 min. zur Autobahn A100</li>
        </ul>
        <br/>
        <h2>Ihre Komfortzone</h2>
        <Div>
          <IFrame
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2428.783826803622!2d13.387401816170694!3d52.50115257981061!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a85123bb2e8f2d%3A0xce98fde0b3c8080f!2sVilhelm7%20Boutique%20Coworking!5e0!3m2!1sde!2sde!4v1584758711374!5m2!1sde!2sde"
            width="100%" height="490" frameBorder="0"  allowFullScreen></IFrame>
        </Div>

<br/>
<br/>
        <h2>Bequem anreisen</h2>
        <br/>

        <h3>PKW</h3>
        <P>Auf der AVUS Richtung Berlin Zentrum (Zoo) fahren, der Beschilderung in Richtung Charlottenburg folgen,
          auf der AVUS bleiben bis zur Abfahrt Tempelhofer Damm. Danach links abbiegen und dem Tempelhofer Damm folgen.
          Im weiteren Verlauf wechselt der Strassenname in Mehringdamm und später in Wilhelmstrasse.
          Vilhelm7 befindet sich an der Ecke Franz-Klühs-Strasse.</P>


        <h3>Flughafen Tegel</h3>
        <P>
          Die Buslinie 128 bis zum Kurt-Schumacher-Platz nehmen, umsteigen in die U6 (in Richtung Alt-Mariendorf), aussteigen am Halleschen Tor,
          den Ausgang zur Friedrichstrasse nehmen, über die Friedrichstrasse nach links in die Franz-Klühs-Strasse zur Wilhelmstrasse laufen.
          Vilhelm7 liegt rechts an der Ecke.</P>

        <h3>Flughafen Schönefeld</h3>
        <P>
          Den Regionalzug am Bahnhof Schönefeld RE 7 oder RB 14 bis zur Friedrichstrasse nehmen, umsteigen in die U6 (in Richtung Alt-Mariendorf),
          aussteigen am Halleschen Tor und den Ausgang zur Friedrichstrasse nehmen,
          über die Friedrichstrasse nach links in die Franz-Klühs-Strasse gehen. Vilhelm7 liegt rechts an der Ecke.</P>


        <h3>ZOB (Zentraler Omnibusbahnhof)</h3>
        <P>
          Zum Kaiserdamm laufen und mit der U2 (in Richtung Pankow) bis zum Wittenbergplatz fahren, umsteigen in die U1 (in Richtung Warschauer Strasse). Am Halleschen Tor aussteigen und den Ausgang zum Mehringplatz wählen.
          Überqueren Sie den Mehringplatz und an der Friedrichstrasse biegen Sie links in die Franz-Klühs-Strasse ein. Vilhelm 7 befindet sich auf der rechten Seite.</P>

        <h3>Hauptbahnhof</h3>
        <P>Vom Europaplatz mit dem Bus der Linie M41 bis zur Station Willy-Brandt-Haus fahren,
          nach links in die Wilhelmstrasse laufen. Vilhelm7 befindet sich auf der rechten Seite, Ecke Franz-Klühs-Strasse.</P>

        <h2>Werden Sie Teil unserer V7-Community und erhalten Sie regelmäßig exklusive Angebote.</h2>

      </Container>
    </Page>
  </IndexLayout>
    )
  }
}

export default Lage
